exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-code-js": () => import("./../../../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-snap-js": () => import("./../../../src/pages/snap.js" /* webpackChunkName: "component---src-pages-snap-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-design-gallery-js": () => import("./../../../src/templates/DesignGallery.js" /* webpackChunkName: "component---src-templates-design-gallery-js" */),
  "component---src-templates-snap-gallery-js": () => import("./../../../src/templates/SnapGallery.js" /* webpackChunkName: "component---src-templates-snap-gallery-js" */)
}

